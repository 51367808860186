<template>
    <div class="box d-flex flex-column flex-1">
        <!-- pc -->
        <div class="mainPC flex-1" style="width: 1250px;" v-if="!isLoading && !isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20231225/pc_bg.jpg" alt="">
            <div class="content">
                <div class="topBox">
                    <img src="../../assets/imgs/activity/20231225/pc_topBg.png" alt="" class="topBg">
                    <div class="list d-flex flex-wrap">
                        <div class="item" v-for="(item, index) in giftList" :key="index">
                            <div class="imgBox">
                                <img :src="item.iconUrl" alt="" :class="['img', { 'active': item.receivedStatus }]">
                            </div>
                            <div class="text">{{ item.name }}</div>
                        </div>
                    </div>
                    <div :class="['btn', { 'btn1': receivedStatus == 0 }, { 'btn2': receivedStatus == 1 }, { 'btn3': receivedStatus == 2 }]"
                        @click="receiveHandle"></div>
                    <div class="receivedText" v-if="receivedStatus == 2">{{ receivedGiftName }}</div>
                </div>
                <div class="ruleBox">
                    <img src="../../assets/imgs/activity/20231225/pc_ruleBg.png" alt="" class="ruleBg">
                </div>

                <div class="descBox">
                    <img src="../../assets/imgs/activity/20231225/pc_bottom.png" alt="" class="bottomBg">
                </div>
            </div>

            <div class="receivePop" v-if="isShowReceivePop">
                <div class="mask"></div>
                <div class="popBox">
                    <div class="text">已点亮{{ receivedCount }}个礼物，兑换超值礼物，只可选择一档兑换，是否继续？</div>
                    <div class="btnBox d-flex a-center j-center">
                        <div class="btn cancel" @click="cancelHandle"></div>
                        <div class="btn confirm" @click="confirmHandle"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- app -->
        <div class="main" v-if="!isLoading && isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20231225/h5_bg.jpg" alt="">
            <div class="content">
                <div class="topBox">
                    <img src="../../assets/imgs/activity/20231225/h5_topBg.png" alt="" class="topBg">
                    <div class="list d-flex flex-wrap">
                        <div class="item" v-for="(item, index) in giftList" :key="index">
                            <div class="imgBox">
                                <img :src="item.iconUrl" alt="" :class="['img', { 'active': item.receivedStatus }]">
                            </div>
                            <div class="text">{{ item.name }}</div>
                        </div>
                    </div>
                    <div :class="['btn', { 'btn1': receivedStatus == 0 }, { 'btn2': receivedStatus == 1 }, { 'btn3': receivedStatus == 2 }]"
                        @click="receiveHandle"></div>
                    <div class="receivedText" v-if="receivedStatus == 2">{{ receivedGiftName }}</div>
                </div>
                <div class="ruleBox">
                    <img src="../../assets/imgs/activity/20231225/h5_ruleBg.png" alt="" class="ruleBg">
                </div>

                <div class="descBox">
                    <img src="../../assets/imgs/activity/20231225/h5_bottom.png" alt="" class="bottomBg">
                </div>
            </div>

            <div class="receivePop" v-if="isShowReceivePop">
                <div class="mask"></div>
                <div class="popBox">
                    <div class="text">已点亮{{ receivedCount }}个礼物，兑换超值礼物，只可选择一档兑换，是否继续？</div>
                    <div class="btnBox d-flex a-center j-center">
                        <div class="btn cancel" @click="cancelHandle"></div>
                        <div class="btn confirm" @click="confirmHandle"></div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    getGiftListForChristmas,
    receiveGiftForChristmas,
} from "@/util/api/api.js"
export default {
    data() {
        return {
            isLoading: true,
            isMobile: false,// 是否为移动端打开
            giftList: [],// 礼物列表
            receivedStatus: 0,// 兑换按钮状态，0、不可兑换 1、可兑换 2、已兑换
            receivedCount: 0,// 已获取礼物数量
            receivedGiftName: '',// 已兑换礼物文案
            isShowReceivePop: false,
        }
    },
    async created() {
        const userAgent = window.navigator.userAgent;
        console.log(userAgent)
        if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
            // 如果是在移动端打开，则认为当前设备是移动设备
            this.isMobile = true;
        }
        this._getGiftListForChristmas()
    },
    methods: {
        // 获取圣诞节活动礼物列表
        async _getGiftListForChristmas() {
            try {
                let res = await getGiftListForChristmas()
                if (res.resultCode == '000000') {
                    this.receivedStatus = res.data.receivedStatus
                    this.receivedCount = res.data.receivedCount
                    this.receivedGiftName = res.data.receivedGiftName || ''
                    if (res.data && res.data.list) {
                        this.giftList = res.data.list
                        this.isLoading = false
                    }
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
                this.isLoading = false
            }
        },

        // 打开确认兑换弹窗
        receiveHandle() {
            if (this.receivedStatus != 1) {
                return
            }
            this.isShowReceivePop = true
        },

        // 取消兑换
        cancelHandle() {
            this.isShowReceivePop = false
        },

        // 确认兑换
        async confirmHandle() {
            if (this.receiveing) {
                return
            }
            this.receiveing = true
            try {
                let res = await receiveGiftForChristmas()
                if (res.resultCode == '000000') {
                    this.$message.success('兑换成功')
                    this._getGiftListForChristmas()
                }
                this.receiveing = false
            } catch (error) {
                console.log(error)
                this.receiveing = false
            }
            this.isShowReceivePop = false
        },
    },
}
</script>

<style lang="less" scoped>
.box {
    color: #fff;
    background: #8E2121;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    // PC端
    .mainPC {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 1920px;
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
        }

        .content {
            position: relative;
            z-index: 1;

            .topBox {
                height: 2318px;
                width: 1216px;
                position: relative;
                margin: 596px auto 0;
                padding: 1px;
                box-sizing: border-box;

                .topBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }

                .list {
                    position: relative;
                    margin-left: 158px;
                    margin-top: 536px;
                    z-index: 1;

                    .item {
                        width: 200px;
                        box-sizing: border-box;
                        margin-right: 42px;
                        margin-bottom: 32px;

                        .imgBox {
                            width: 200px;
                            height: 200px;
                            background: #32090C;
                            border-radius: 12px;
                            border: 2px solid #A6313A;
                            overflow: hidden;

                            .img {
                                height: 100%;
                                width: 100%;
                                display: block;
                                object-fit: contain;
                                opacity: .2;

                                &.active {
                                    opacity: 1;
                                }
                            }
                        }

                        .text {
                            text-align: center;
                            font-size: 24px;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-top: 12px;
                        }
                    }
                }

                .btn {
                    position: absolute;
                    z-index: 1;
                    height: 93px;
                    width: 250px;
                    left: 495px;
                    bottom: 234px;
                    cursor: pointer;

                    &.btn1 {
                        background: url(../../assets/imgs/activity/20231225/icon_btn_noReceive.png)no-repeat center/cover;
                    }

                    &.btn2 {
                        background: url(../../assets/imgs/activity/20231225/icon_btn_receive.png)no-repeat center/cover;
                    }

                    &.btn3 {
                        background: url(../../assets/imgs/activity/20231225/icon_btn_received.png)no-repeat center/cover;
                    }
                }

                .receivedText {
                    position: absolute;
                    bottom: 60px;
                    left: 50%;
                    margin-left: -400px;
                    text-align: center;
                    z-index: 1;
                    width: 800px;
                    height: 160px;
                    background: #501318;
                    font-size: 30px;
                    font-weight: 500;
                    color: #ffea00;
                    line-height: 100px;
                }
            }

            .ruleBox {
                padding-top: 72px;
                height: 1854px;
                width: 1216px;
                position: relative;
                margin: 0 auto;

                .ruleBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }
            }

            .descBox {
                margin: 0 auto;
                padding-top: 72px;
                padding-bottom: 96px;
                height: 871px;
                width: 1139px;
                position: relative;

                .bottomBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }
            }
        }

        .receivePop {
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            min-width: 1250px;
            width: 100vw;
            margin: 0 auto;
            z-index: 1;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 100%;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
                z-index: 1;
            }

            .popBox {
                animation: showCenterPop ease .3s;
                width: 680px;
                height: 290px;
                background: #276F53;
                box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 0.5);
                border-radius: 48px;

                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -340px;
                margin-top: -160px;
                z-index: 1;

                .text {
                    width: 430px;
                    font-size: 28px;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 48px;
                    margin: 62px auto 0;
                }

                .btnBox {
                    margin: 35px auto 0;

                    .btn {
                        height: 62px;
                        width: 215px;
                        cursor: pointer;

                        &.cancel {
                            background: url(../../assets/imgs/activity/20231225/icon_btn_cancel.png)no-repeat center/cover;
                            margin-right: 55px;
                        }

                        &.confirm {
                            background: url(../../assets/imgs/activity/20231225/icon_btn_confirm.png)no-repeat center/cover;
                        }
                    }
                }

            }
        }
    }

    // 移动端
    .main {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;
        background: #8D2021;

        .bg_activity {
            width: 100vw;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }

        .content {
            position: relative;
            z-index: 1;

            .topBox {
                height: 222.4vw;
                width: 97.6vw;
                position: relative;
                margin: 53.6vw auto 0;
                padding: 1px;
                box-sizing: border-box;

                .topBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }

                .list {
                    position: relative;
                    margin-left: 10.133vw;
                    margin-top: 48vw;
                    z-index: 1;

                    .item {
                        width: 17.067vw;
                        box-sizing: border-box;
                        margin-right: 3.467vw;
                        margin-bottom: 3.2vw;

                        .imgBox {
                            width: 17.067vw;
                            height: 17.067vw;
                            background: #32090C;
                            border-radius: 3.2vw;
                            border: 0.267vw solid #A6313A;
                            overflow: hidden;

                            .img {
                                height: 100%;
                                width: 100%;
                                display: block;
                                object-fit: contain;
                                opacity: .2;

                                &.active {
                                    opacity: 1;
                                }
                            }
                        }

                        .text {
                            text-align: center;
                            font-size: 2.667vw;
                            font-family: PingFangSC, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                            margin-top: 1.067vw;
                        }
                    }
                }

                .btn {
                    position: absolute;
                    z-index: 1;
                    height: 8.533vw;
                    width: 23.2vw;
                    left: 36.8vw;
                    bottom: 28.8vw;
                    cursor: pointer;
                    -webkit-tap-highlight-color: transparent;
                    /* 高亮颜色设置为透明 */

                    &.btn1 {
                        background: url(../../assets/imgs/activity/20231225/icon_btn_noReceive.png)no-repeat center/cover;
                    }

                    &.btn2 {
                        background: url(../../assets/imgs/activity/20231225/icon_btn_receive.png)no-repeat center/cover;
                    }

                    &.btn3 {
                        background: url(../../assets/imgs/activity/20231225/icon_btn_received.png)no-repeat center/cover;
                    }
                }

                .receivedText {
                    position: absolute;
                    bottom: 8vw;
                    left: 50%;
                    margin-left: -40vw;
                    text-align: center;
                    z-index: 1;
                    width: 80vw;
                    height: 18.667vw;
                    background: #501318;
                    font-size: 3.2vw;
                    font-weight: 500;
                    color: #ffea00;
                    line-height: 10.667vw;
                }
            }

            .ruleBox {
                padding-top: 8.533vw;
                height: 148.8vw;
                width: 97.867vw;
                position: relative;
                margin: 0 auto;

                .ruleBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }
            }

            .descBox {
                margin: 0 auto;
                padding-top: 8.533vw;
                padding-bottom: 6.667vw;
                height: 147.733vw;
                width: 86.133vw;
                position: relative;

                .bottomBg {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 1;
                }
            }
        }

        .receivePop {
            position: fixed;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100vw;
            margin: 0 auto;
            z-index: 1;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 100%;
                width: 100%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
                z-index: 1;
            }

            .popBox {
                animation: showCenterPop ease .3s;
                width: 79.467vw;
                height: 40.267vw;
                background: #276F53;
                box-shadow: inset 0px 0px 2.667vw 0px rgba(255, 255, 255, 0.5);
                border-radius: 6.4vw;

                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 50%;
                margin-left: -39.733vw;
                margin-top: -20.133vw;
                z-index: 1;

                .text {
                    width: 58vw;
                    font-size: 3.733vw;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 5.333vw;
                    margin: 7.733vw auto;
                }

                .btnBox {
                    margin: 0 auto;

                    .btn {
                        height: 9.333vw;
                        width: 27.467vw;
                        cursor: pointer;
                        -webkit-tap-highlight-color: transparent;
                        /* 高亮颜色设置为透明 */

                        &.cancel {
                            background: url(../../assets/imgs/activity/20231225/icon_btn_cancel_h5.png)no-repeat center/cover;
                            margin-right: 6.133vw;
                        }

                        &.confirm {
                            background: url(../../assets/imgs/activity/20231225/icon_btn_confirm_h5.png)no-repeat center/cover;
                        }
                    }
                }

            }
        }
    }
}
</style>
