var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box d-flex flex-column flex-1" }, [
    !_vm.isLoading && !_vm.isMobile
      ? _c(
          "div",
          { staticClass: "mainPC flex-1", staticStyle: { width: "1200px" } },
          [
            _c("img", {
              staticClass: "bg_activity",
              attrs: {
                src: require("../../assets/imgs/activity/20231111/pc_bg.jpg"),
                alt: "",
              },
            }),
            _c("img", {
              staticClass: "topTitle",
              attrs: {
                src: require("../../assets/imgs/activity/20231111/pc_title.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "content" }, [
              _vm.rankList[0]
                ? _c("div", { staticClass: "top1Box" }, [
                    _c("img", {
                      staticClass: "iconWing",
                      attrs: {
                        src: require("../../assets/imgs/activity/20231111/icon_wing.gif"),
                        alt: "",
                      },
                    }),
                    _c("img", {
                      staticClass: "topBg",
                      attrs: {
                        src: require("../../assets/imgs/activity/20231111/pc_TOP1.png"),
                        alt: "",
                      },
                    }),
                    _c("img", {
                      staticClass: "roomImg",
                      attrs: { src: _vm.rankList[0].adUrl, alt: "" },
                    }),
                    _c("div", { staticClass: "roomName d-overflow" }, [
                      _vm._v(_vm._s(_vm.rankList[0].name)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "hotBox d-flex a-center j-center" },
                      [
                        _c("img", {
                          staticClass: "iconHot",
                          attrs: {
                            src: require("../../assets/imgs/activity/20231111/icon_hot.png"),
                            alt: "",
                          },
                        }),
                        _c("div", { staticClass: "hotNumber" }, [
                          _vm._v(_vm._s(_vm.rankList[0].num)),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "d-flex a-center j-center" }, [
                _vm.rankList[1]
                  ? _c("div", { staticClass: "top2Box" }, [
                      _c("img", {
                        staticClass: "topBg",
                        attrs: {
                          src: require("../../assets/imgs/activity/20231111/pc_TOP2.png"),
                          alt: "",
                        },
                      }),
                      _c("img", {
                        staticClass: "roomImg",
                        attrs: { src: _vm.rankList[1].adUrl, alt: "" },
                      }),
                      _c("div", { staticClass: "roomName d-overflow" }, [
                        _vm._v(_vm._s(_vm.rankList[1].name)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "hotBox d-flex a-center j-center" },
                        [
                          _c("img", {
                            staticClass: "iconHot",
                            attrs: {
                              src: require("../../assets/imgs/activity/20231111/icon_hot.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "hotNumber" }, [
                            _vm._v(_vm._s(_vm.rankList[1].num)),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm.rankList[2]
                  ? _c("div", { staticClass: "top3Box" }, [
                      _c("img", {
                        staticClass: "topBg",
                        attrs: {
                          src: require("../../assets/imgs/activity/20231111/pc_TOP3.png"),
                          alt: "",
                        },
                      }),
                      _c("img", {
                        staticClass: "roomImg",
                        attrs: { src: _vm.rankList[2].adUrl, alt: "" },
                      }),
                      _c("div", { staticClass: "roomName d-overflow" }, [
                        _vm._v(_vm._s(_vm.rankList[2].name)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "hotBox d-flex a-center j-center" },
                        [
                          _c("img", {
                            staticClass: "iconHot",
                            attrs: {
                              src: require("../../assets/imgs/activity/20231111/icon_hot.png"),
                              alt: "",
                            },
                          }),
                          _c("div", { staticClass: "hotNumber" }, [
                            _vm._v(_vm._s(_vm.rankList[2].num)),
                          ]),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm.rankList.length > 3
                ? _c(
                    "div",
                    { staticClass: "list" },
                    _vm._l(_vm.rankList, function (item, index) {
                      return _c("div", { key: index }, [
                        index >= 3
                          ? _c(
                              "div",
                              {
                                class: [
                                  "item d-flex a-center",
                                  { itemBg: (index + 1) % 2 == 0 },
                                ],
                              },
                              [
                                _c("div", { staticClass: "sortNum" }, [
                                  _vm._v(_vm._s(index + 1)),
                                ]),
                                _c("img", {
                                  staticClass: "roomImg",
                                  attrs: { src: item.adUrl, alt: "" },
                                }),
                                _c(
                                  "div",
                                  { staticClass: "roomName d-overflow flex-1" },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "hotBox d-flex a-center j-center",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "iconHot",
                                      attrs: {
                                        src: require("../../assets/imgs/activity/20231111/icon_hot.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "hotNumber" }, [
                                      _vm._v(_vm._s(item.num)),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._m(0),
              _vm._m(1),
              _vm._m(2),
            ]),
          ]
        )
      : _vm._e(),
    !_vm.isLoading && _vm.isMobile
      ? _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "bg_activity",
            attrs: {
              src: require("../../assets/imgs/activity/20231111/h5_bg.jpg"),
              alt: "",
            },
          }),
          _c("img", {
            staticClass: "topTitle",
            attrs: {
              src: require("../../assets/imgs/activity/20231111/h5_title.png"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "content" }, [
            _vm.rankList[0]
              ? _c("div", { staticClass: "top1Box topBox" }, [
                  _c("img", {
                    staticClass: "iconWing",
                    attrs: {
                      src: require("../../assets/imgs/activity/20231111/icon_wing.gif"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "topBg",
                    attrs: {
                      src: require("../../assets/imgs/activity/20231111/pc_TOP1.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "roomImg",
                    attrs: { src: _vm.rankList[0].adUrl, alt: "" },
                  }),
                  _c("div", { staticClass: "roomName d-overflow" }, [
                    _vm._v(_vm._s(_vm.rankList[0].name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "hotBox d-flex a-center j-center" },
                    [
                      _c("img", {
                        staticClass: "iconHot",
                        attrs: {
                          src: require("../../assets/imgs/activity/20231111/icon_hot.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "hotNumber" }, [
                        _vm._v(_vm._s(_vm.rankList[0].num)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.rankList[1]
              ? _c("div", { staticClass: "top2Box topBox" }, [
                  _c("img", {
                    staticClass: "topBg",
                    attrs: {
                      src: require("../../assets/imgs/activity/20231111/pc_TOP2.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "roomImg",
                    attrs: { src: _vm.rankList[1].adUrl, alt: "" },
                  }),
                  _c("div", { staticClass: "roomName d-overflow" }, [
                    _vm._v(_vm._s(_vm.rankList[1].name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "hotBox d-flex a-center j-center" },
                    [
                      _c("img", {
                        staticClass: "iconHot",
                        attrs: {
                          src: require("../../assets/imgs/activity/20231111/icon_hot.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "hotNumber" }, [
                        _vm._v(_vm._s(_vm.rankList[1].num)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.rankList[2]
              ? _c("div", { staticClass: "top3Box topBox" }, [
                  _c("img", {
                    staticClass: "topBg",
                    attrs: {
                      src: require("../../assets/imgs/activity/20231111/pc_TOP3.png"),
                      alt: "",
                    },
                  }),
                  _c("img", {
                    staticClass: "roomImg",
                    attrs: { src: _vm.rankList[2].adUrl, alt: "" },
                  }),
                  _c("div", { staticClass: "roomName d-overflow" }, [
                    _vm._v(_vm._s(_vm.rankList[2].name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "hotBox d-flex a-center j-center" },
                    [
                      _c("img", {
                        staticClass: "iconHot",
                        attrs: {
                          src: require("../../assets/imgs/activity/20231111/icon_hot.png"),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "hotNumber" }, [
                        _vm._v(_vm._s(_vm.rankList[2].num)),
                      ]),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm.rankList.length > 3
              ? _c(
                  "div",
                  { staticClass: "list" },
                  _vm._l(_vm.rankList, function (item, index) {
                    return _c("div", { key: index }, [
                      index >= 3
                        ? _c(
                            "div",
                            {
                              class: [
                                "item d-flex a-center",
                                { itemBg: (index + 1) % 2 == 0 },
                              ],
                            },
                            [
                              _c("div", { staticClass: "sortNum" }, [
                                _vm._v(_vm._s(index + 1)),
                              ]),
                              _c("img", {
                                staticClass: "roomImg",
                                attrs: { src: item.adUrl, alt: "" },
                              }),
                              _c("div", { staticClass: "right" }, [
                                _c(
                                  "div",
                                  { staticClass: "roomName d-overflow" },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "hotBox d-flex a-center" },
                                  [
                                    _c("img", {
                                      staticClass: "iconHot",
                                      attrs: {
                                        src: require("../../assets/imgs/activity/20231111/icon_hot.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("div", { staticClass: "hotNumber" }, [
                                      _vm._v(_vm._s(item.num)),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._m(3),
            _vm._m(4),
            _vm._m(5),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("img", {
        staticClass: "ruleTitle",
        attrs: {
          src: require("../../assets/imgs/activity/20231111/pc_ruleTitle.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "ruleContent" }, [
        _c("p", [
          _vm._v(" 1、活动时间为2023年11月6日0点至2023年11月12日24点"),
          _c("br"),
          _vm._v(
            " 2、活动期间，厅内收到礼包+礼物均可获得热度值（每收到10随心钻的礼物/礼包该厅增加1点热度值）"
          ),
          _c("br"),
          _vm._v(
            " 3、前十名奖励中的52钻礼物为限定礼物“尊嘟假嘟”，无其他渠道获取，活动结束后24小时内发放到厅管背包"
          ),
          _c("br"),
          _vm._v(
            " 4、定制系列可定制厅头像框，厅坐骑，厅气泡（均为300天）或个人头像框，个人坐骑，个人气泡（永久），2选1，活动结束后联系运营进行定制。 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rewardBox" }, [
      _c("img", {
        staticClass: "rewardTitle",
        attrs: {
          src: require("../../assets/imgs/activity/20231111/pc_rewardTitle.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "rewardContent" }, [
        _c("p", [
          _vm._v(" 【第一名】"),
          _c("br"),
          _vm._v(
            " 52钻石礼物*1000 / 定制坐骑*1 / 定制头像框*1 / 定制气泡*1 / 一个月厅推荐位 / 全套虚拟装扮任选*30天"
          ),
          _c("br"),
          _vm._v(" 【第二名】"),
          _c("br"),
          _vm._v(
            " 52钻石礼物*600 / 定制头像框*1 / 定制气泡*1 / 2周推荐位 / 全套虚拟装扮任选*30天"
          ),
          _c("br"),
          _vm._v(" 【第三名】"),
          _c("br"),
          _vm._v(
            " 52钻石礼物*300 / 定制头像框*1 / 一周推荐位 / 全套虚拟装扮任选*30天"
          ),
          _c("br"),
          _vm._v(" 【第4~6名】"),
          _c("br"),
          _vm._v(" 52钻礼物*100 / 全套虚拟装扮任选*15天"),
          _c("br"),
          _vm._v(" 【第7~10名】"),
          _c("br"),
          _vm._v(" 52钻礼物*50 / 全套虚拟装扮任选*15天"),
          _c("br"),
        ]),
        _c("p", { staticClass: "desc" }, [
          _vm._v(
            "52钻礼物为限定特殊礼物，活动结束后直接发放到厅管背包，定制坐骑头像可做厅定制，联系运营"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _vm._v(
        " 在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；"
      ),
      _c("br"),
      _vm._v(" 如有疑问请联系客服QQ: 1058127957进行咨询 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("img", {
        staticClass: "ruleTitle",
        attrs: {
          src: require("../../assets/imgs/activity/20231111/h5_ruleTitle.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "ruleContent" }, [
        _c("p", [
          _vm._v(" 1、活动时间为2023年11月6日0点至2023年11月12日24点"),
          _c("br"),
          _vm._v(
            " 2、活动期间，厅内收到礼包+礼物均可获得热度值（每收到10随心钻的礼物/礼包该厅增加1点热度值）"
          ),
          _c("br"),
          _vm._v(
            " 3、前十名奖励中的52钻礼物为限定礼物“尊嘟假嘟”，无其他渠道获取，活动结束后24小时内发放到厅管背包"
          ),
          _c("br"),
          _vm._v(
            " 4、定制系列可定制厅头像框，厅坐骑，厅气泡（均为300天）或个人头像框，个人坐骑，个人气泡（永久），2选1，活动结束后联系运营进行定制。 "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rewardBox" }, [
      _c("img", {
        staticClass: "rewardTitle",
        attrs: {
          src: require("../../assets/imgs/activity/20231111/h5_rewardTitle.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "rewardContent" }, [
        _c("p", [
          _vm._v(" 【第一名】"),
          _c("br"),
          _vm._v(
            " 52钻石礼物*1000 / 定制坐骑*1 / 定制头像框*1 / 定制气泡*1 / 一个月厅推荐位 / 全套虚拟装扮任选*30天"
          ),
          _c("br"),
          _vm._v(" 【第二名】"),
          _c("br"),
          _vm._v(
            " 52钻石礼物*600 / 定制头像框*1 / 定制气泡*1 / 2周推荐位 / 全套虚拟装扮任选*30天"
          ),
          _c("br"),
          _vm._v(" 【第三名】"),
          _c("br"),
          _vm._v(
            " 52钻石礼物*300 / 定制头像框*1 / 一周推荐位 / 全套虚拟装扮任选*30天"
          ),
          _c("br"),
          _vm._v(" 【第4~6名】"),
          _c("br"),
          _vm._v(" 52钻礼物*100 / 全套虚拟装扮任选*15天"),
          _c("br"),
          _vm._v(" 【第7~10名】"),
          _c("br"),
          _vm._v(" 52钻礼物*50 / 全套虚拟装扮任选*15天"),
          _c("br"),
        ]),
        _c("p", { staticClass: "desc" }, [
          _vm._v(
            "52钻礼物为限定特殊礼物，活动结束后直接发放到厅管背包，定制坐骑头像可做厅定制，联系运营"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _vm._v(
        " 在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；"
      ),
      _c("br"),
      _vm._v(" 如有疑问请联系客服QQ: 1058127957进行咨询 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }