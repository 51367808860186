<template>
    <div class="box d-flex flex-column flex-1">
        <!-- pc -->
        <div class="mainPC flex-1" style="width: 1200px;" v-if="!isLoading && !isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20231111/pc_bg.jpg" alt="">
            <img src="../../assets/imgs/activity/20231111/pc_title.png" alt="" class="topTitle">
            <div class="content">
                <div class="top1Box" v-if="rankList[0]">
                    <img src="../../assets/imgs/activity/20231111/icon_wing.gif" alt="" class="iconWing">
                    <img src="../../assets/imgs/activity/20231111/pc_TOP1.png" alt="" class="topBg">
                    <img :src="rankList[0].adUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[0].name }}</div>
                    <div class="hotBox d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/20231111/icon_hot.png" alt="" class="iconHot">
                        <div class="hotNumber">{{ rankList[0].num }}</div>
                    </div>
                </div>
                <div class="d-flex a-center j-center">
                    <div class="top2Box" v-if="rankList[1]">
                        <img src="../../assets/imgs/activity/20231111/pc_TOP2.png" alt="" class="topBg">
                        <img :src="rankList[1].adUrl" alt="" class="roomImg">
                        <div class="roomName d-overflow">{{ rankList[1].name }}</div>
                        <div class="hotBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231111/icon_hot.png" alt="" class="iconHot">
                            <div class="hotNumber">{{ rankList[1].num }}</div>
                        </div>
                    </div>
                    <div class="top3Box" v-if="rankList[2]">
                        <img src="../../assets/imgs/activity/20231111/pc_TOP3.png" alt="" class="topBg">
                        <img :src="rankList[2].adUrl" alt="" class="roomImg">
                        <div class="roomName d-overflow">{{ rankList[2].name }}</div>
                        <div class="hotBox d-flex a-center j-center">
                            <img src="../../assets/imgs/activity/20231111/icon_hot.png" alt="" class="iconHot">
                            <div class="hotNumber">{{ rankList[2].num }}</div>
                        </div>
                    </div>
                </div>

                <div class="list" v-if="rankList.length > 3">
                    <div v-for="(item, index) in rankList" :key="index">
                        <div v-if="index >= 3" :class="['item d-flex a-center', { itemBg: (index + 1) % 2 == 0 }]">
                            <div class="sortNum">{{ index + 1 }}</div>
                            <img :src="item.adUrl" alt="" class="roomImg">
                            <div class="roomName d-overflow flex-1">{{ item.name }}</div>
                            <div class="hotBox d-flex a-center j-center">
                                <img src="../../assets/imgs/activity/20231111/icon_hot.png" alt="" class="iconHot">
                                <div class="hotNumber">{{ item.num }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ruleBox">
                    <img src="../../assets/imgs/activity/20231111/pc_ruleTitle.png" alt="" class="ruleTitle">
                    <div class="ruleContent">
                        <p>
                            1、活动时间为2023年11月6日0点至2023年11月12日24点<br />
                            2、活动期间，厅内收到礼包+礼物均可获得热度值（每收到10随心钻的礼物/礼包该厅增加1点热度值）<br />
                            3、前十名奖励中的52钻礼物为限定礼物“尊嘟假嘟”，无其他渠道获取，活动结束后24小时内发放到厅管背包<br />
                            4、定制系列可定制厅头像框，厅坐骑，厅气泡（均为300天）或个人头像框，个人坐骑，个人气泡（永久），2选1，活动结束后联系运营进行定制。
                        </p>
                    </div>
                </div>

                <div class="rewardBox">
                    <img src="../../assets/imgs/activity/20231111/pc_rewardTitle.png" alt="" class="rewardTitle">
                    <div class="rewardContent">
                        <p>
                            【第一名】<br />
                            52钻石礼物*1000 / 定制坐骑*1 / 定制头像框*1 / 定制气泡*1 / 一个月厅推荐位 / 全套虚拟装扮任选*30天<br />
                            【第二名】<br />
                            52钻石礼物*600 / 定制头像框*1 / 定制气泡*1 / 2周推荐位 / 全套虚拟装扮任选*30天<br />
                            【第三名】<br />
                            52钻石礼物*300 / 定制头像框*1 / 一周推荐位 / 全套虚拟装扮任选*30天<br />
                            【第4~6名】<br />
                            52钻礼物*100 / 全套虚拟装扮任选*15天<br />
                            【第7~10名】<br />
                            52钻礼物*50 / 全套虚拟装扮任选*15天<br />
                        </p>
                        <p class="desc">52钻礼物为限定特殊礼物，活动结束后直接发放到厅管背包，定制坐骑头像可做厅定制，联系运营</p>
                    </div>
                </div>

                <div class="descBox">
                    在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；<br />
                    如有疑问请联系客服QQ: 1058127957进行咨询
                </div>
            </div>
        </div>

        <!-- app -->
        <div class="main" v-if="!isLoading && isMobile">
            <img class="bg_activity" src="../../assets/imgs/activity/20231111/h5_bg.jpg" alt="">
            <img src="../../assets/imgs/activity/20231111/h5_title.png" alt="" class="topTitle">
            <div class="content">
                <div class="top1Box topBox" v-if="rankList[0]">
                    <img src="../../assets/imgs/activity/20231111/icon_wing.gif" alt="" class="iconWing">
                    <img src="../../assets/imgs/activity/20231111/pc_TOP1.png" alt="" class="topBg">
                    <img :src="rankList[0].adUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[0].name }}</div>
                    <div class="hotBox d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/20231111/icon_hot.png" alt="" class="iconHot">
                        <div class="hotNumber">{{ rankList[0].num }}</div>
                    </div>
                </div>
                <div class="top2Box topBox" v-if="rankList[1]">
                    <img src="../../assets/imgs/activity/20231111/pc_TOP2.png" alt="" class="topBg">
                    <img :src="rankList[1].adUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[1].name }}</div>
                    <div class="hotBox d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/20231111/icon_hot.png" alt="" class="iconHot">
                        <div class="hotNumber">{{ rankList[1].num }}</div>
                    </div>
                </div>
                <div class="top3Box topBox" v-if="rankList[2]">
                    <img src="../../assets/imgs/activity/20231111/pc_TOP3.png" alt="" class="topBg">
                    <img :src="rankList[2].adUrl" alt="" class="roomImg">
                    <div class="roomName d-overflow">{{ rankList[2].name }}</div>
                    <div class="hotBox d-flex a-center j-center">
                        <img src="../../assets/imgs/activity/20231111/icon_hot.png" alt="" class="iconHot">
                        <div class="hotNumber">{{ rankList[2].num }}</div>
                    </div>
                </div>

                <div class="list" v-if="rankList.length > 3">
                    <div v-for="(item, index) in rankList" :key="index">
                        <div v-if="index >= 3" :class="['item d-flex a-center', { itemBg: (index + 1) % 2 == 0 }]">
                            <div class="sortNum">{{ index + 1 }}</div>
                            <img :src="item.adUrl" alt="" class="roomImg">
                            <div class="right">
                                <div class="roomName d-overflow">{{ item.name }}</div>
                                <div class="hotBox d-flex a-center">
                                    <img src="../../assets/imgs/activity/20231111/icon_hot.png" alt="" class="iconHot">
                                    <div class="hotNumber">{{ item.num }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ruleBox">
                    <img src="../../assets/imgs/activity/20231111/h5_ruleTitle.png" alt="" class="ruleTitle">
                    <div class="ruleContent">
                        <p>
                            1、活动时间为2023年11月6日0点至2023年11月12日24点<br />
                            2、活动期间，厅内收到礼包+礼物均可获得热度值（每收到10随心钻的礼物/礼包该厅增加1点热度值）<br />
                            3、前十名奖励中的52钻礼物为限定礼物“尊嘟假嘟”，无其他渠道获取，活动结束后24小时内发放到厅管背包<br />
                            4、定制系列可定制厅头像框，厅坐骑，厅气泡（均为300天）或个人头像框，个人坐骑，个人气泡（永久），2选1，活动结束后联系运营进行定制。
                        </p>
                    </div>
                </div>

                <div class="rewardBox">
                    <img src="../../assets/imgs/activity/20231111/h5_rewardTitle.png" alt="" class="rewardTitle">
                    <div class="rewardContent">
                        <p>
                            【第一名】<br />
                            52钻石礼物*1000 / 定制坐骑*1 / 定制头像框*1 / 定制气泡*1 / 一个月厅推荐位 / 全套虚拟装扮任选*30天<br />
                            【第二名】<br />
                            52钻石礼物*600 / 定制头像框*1 / 定制气泡*1 / 2周推荐位 / 全套虚拟装扮任选*30天<br />
                            【第三名】<br />
                            52钻石礼物*300 / 定制头像框*1 / 一周推荐位 / 全套虚拟装扮任选*30天<br />
                            【第4~6名】<br />
                            52钻礼物*100 / 全套虚拟装扮任选*15天<br />
                            【第7~10名】<br />
                            52钻礼物*50 / 全套虚拟装扮任选*15天<br />
                        </p>
                        <p class="desc">52钻礼物为限定特殊礼物，活动结束后直接发放到厅管背包，定制坐骑头像可做厅定制，联系运营</p>
                    </div>
                </div>

                <div class="descBox">
                    在法律许可范围内，公司有随时调整活动的权利并享有活动的最终解释权，请详细了解规则后再参与；<br />
                    如有疑问请联系客服QQ: 1058127957进行咨询
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    getRankRoomList
} from "@/util/api/api.js"
export default {
    data() {
        return {
            isLoading: true,
            isMobile: false,// 是否为移动端打开
            rankList: [
                {
                    id: Symbol(),
                    name: 'Gq·春风十里-皆不如你',
                    adUrl: 'https://image.gxmuchun.com/management/2023-11-05/1699196414158.jpg',
                    num: 701093
                },
                {
                    id: Symbol(),
                    name: 'Gq丶飘飘欲仙❣天官赐福 百无禁忌',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-25/1698249495123.jpg',
                    num: 641168
                },
                {
                    id: Symbol(),
                    name: '南柯一梦',
                    adUrl: 'https://image.gxmuchun.com/management/2023-11-09/1699499177551.jpg',
                    num: 457255
                },
                {
                    id: Symbol(),
                    name: '匿名爱意?有期待终会有相见',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-14/1697292875736.jpg',
                    num: 444716
                },
                {
                    id: Symbol(),
                    name: '花朝月夕?入职dd',
                    adUrl: 'https://img.sxyy520.com/2023-07-07/upload64a7c0b5adb23cee.jpg',
                    num: 434131
                },
                {
                    id: Symbol(),
                    name: 'Gq·宝宝巴士?幼稚园',
                    adUrl: 'https://image.gxmuchun.com/management/2023-11-06/1699200612780.png',
                    num: 273964
                },
                {
                    id: Symbol(),
                    name: '花间酒·人间月?恭喜諾言亲王',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-22/1697938873908.jpg',
                    num: 250825
                },
                {
                    id: Symbol(),
                    name: 'Gq丶金元宝?发动黑暗技能：世界毁灭吧(;´༎ຶД༎ຶ`)',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-10/1696946990627.jpg',
                    num: 223855
                },
                {
                    id: Symbol(),
                    name: 'Gq丶疯人院',
                    adUrl: 'https://img.sxyy520.com/2023-07-01/upload649f3a841691fc50.jpg',
                    num: 191366
                },
                {
                    id: Symbol(),
                    name: 'Gq丶梦语星辰✨梦始于你✨你是星辰大海',
                    adUrl: 'https://img.sxyy520.com/2022-12-24/upload63a6a69b205f15f8.jpeg',
                    num: 176714
                },
                {
                    id: Symbol(),
                    name: '不负遇见-招主持队长',
                    adUrl: 'https://img.sxyy520.com/2023-05-25/upload646f853507b91f26.jpeg',
                    num: 156813
                },
                {
                    id: Symbol(),
                    name: 'Gq丶半城秋意',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-29/1698595140093.jpg',
                    num: 104373
                },
                {
                    id: Symbol(),
                    name: 'Gq丶甜心妙妙屋?污',
                    adUrl: 'https://img.sxyy520.com/2023-08-05/upload64ce51acd8cdb479.jpg',
                    num: 93029
                },
                {
                    id: Symbol(),
                    name: 'Gq丶四季与你',
                    adUrl: 'https://image.gxmuchun.com/management/2023-11-09/1699463322138.jpg',
                    num: 88079
                },
                {
                    id: Symbol(),
                    name: 'Gq丶快乐就好',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-15/1697371555276.jpeg',
                    num: 69458
                },
                {
                    id: Symbol(),
                    name: 'Gq丶失眠渡',
                    adUrl: 'https://img.sxyy520.com/2023-07-25/upload64bf69c0bbe46db8.jpg',
                    num: 67599
                },
                {
                    id: Symbol(),
                    name: 'Gq丶彩虹岛?平安喜乐',
                    adUrl: 'https://image.gxmuchun.com/management/2023-11-05/1699195705210.jpg',
                    num: 67436
                },
                {
                    id: Symbol(),
                    name: '甜蜜日记',
                    adUrl: 'https://image.gxmuchun.com/management/2023-11-03/1698991381080.jpg',
                    num: 57037
                },
                {
                    id: Symbol(),
                    name: '官方娱乐开黑厅',
                    adUrl: 'https://img.sxyy520.com/2023-06-29/upload649d4b9a67130bfb.jpeg',
                    num: 51108
                },
                {
                    id: Symbol(),
                    name: 'Gq丶不期而遇',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-26/1698316716843.jpg',
                    num: 48503
                },
                {
                    id: Symbol(),
                    name: '星辰大海?目光所致皆是你',
                    adUrl: 'https://img.sxyy520.com/2023-04-12/upload643651450b122f50.jpeg',
                    num: 48170
                },
                {
                    id: Symbol(),
                    name: '初见倾心?招人',
                    adUrl: 'https://img.sxyy520.com/2023-07-23/upload64bd2de5a1e559eb.jpg',
                    num: 41643
                },
                {
                    id: Symbol(),
                    name: '告白之夜?高薪聘厅管',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-03/1696337336159.jpg',
                    num: 35153
                },
                {
                    id: Symbol(),
                    name: '慕音点唱 ?专业歌手厅',
                    adUrl: 'https://img.sxyy520.com/2023-08-07/upload64d055102daab34a.jpeg',
                    num: 35144
                },
                {
                    id: Symbol(),
                    name: '说晚安',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-30/1698643533640.png',
                    num: 34274
                },
                {
                    id: Symbol(),
                    name: 'Gq`玫瑰诗笺☾',
                    adUrl: 'https://image.gxmuchun.com/management/2023-09-09/1694240995912.png',
                    num: 33843
                },
                {
                    id: Symbol(),
                    name: 'Gq丶四季sweet',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-21/1697886876131.jpeg',
                    num: 16258
                },
                {
                    id: Symbol(),
                    name: '官方活动厅',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-09/1696844479837.jpg',
                    num: 16012
                },
                {
                    id: Symbol(),
                    name: '赊海棠',
                    adUrl: 'https://image.gxmuchun.com/management/2023-10-20/1697793683911.jpg',
                    num: 15352
                },
                {
                    id: Symbol(),
                    name: '无忧当铺?',
                    adUrl: 'https://image.gxmuchun.com/management/2023-09-06/1693996997525.jpg',
                    num: 12319
                },
                {
                    id: Symbol(),
                    name: 'Gq丶搞钱吧培训基地',
                    adUrl: 'https://image.gxmuchun.com/management/2023-08-26/1693051345948.jpg',
                    num: 7046
                },
                {
                    id: Symbol(),
                    name: '一梦惊鸿?13号正式开业~',
                    adUrl: 'https://image.gxmuchun.com/management/2023-11-06/1699280874822.jpg',
                    num: 13
                },
            ],// 厅流水排行榜列表
        }
    },
    async created() {
        const userAgent = window.navigator.userAgent;
        console.log(userAgent)
        if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
            // 如果是在移动端打开，则认为当前设备是移动设备
            this.isMobile = true;
        }
        // this._getRankRoomList()
        this.isLoading = false
    },
    methods: {
        // 获取厅流水排行榜
        async _getRankRoomList() {
            try {
                let res = await getRankRoomList()
                if (res.resultCode == '000000') {
                    console.log(res)
                    if (res.data && res.data.list) {
                        this.rankList = res.data.list
                        this.isLoading = false
                    }
                }
                this.isLoading = false
            } catch (error) {
                console.log(error)
                this.isLoading = false
            }
        }
    },
}
</script>

<style lang="less" scoped>
.box {
    color: #fff;
    background: #000000;
    min-height: 100vh;
    position: relative;
    overflow: hidden;

    // PC端
    .mainPC {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 1920px;
            display: block;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
            top: 0;
        }

        .topTitle {
            position: relative;
            margin: 152px auto 0;
            display: block;
            height: 480px;
            width: 679px;
            z-index: 1;
        }

        .content {
            position: relative;
            z-index: 1;

            .top1Box {
                position: relative;
                margin: 190px auto 0;
                width: 280px;
                padding: 1px;
                box-sizing: border-box;

                .iconWing {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    width: 664px;
                    transform: translate(-50%, -67%);
                }

                .topBg {
                    position: absolute;
                    display: block;
                    width: 280px;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    height: 180px;
                    width: 180px;
                    background: #ccc;
                    border-radius: 16px;
                    margin: 84px auto 0;
                }

                .roomName {
                    position: relative;
                    width: 180px;
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;
                    margin: 35px auto 0;
                }

                .hotBox {
                    position: relative;
                    margin-top: 34px;

                    .iconHot {
                        display: block;
                        height: 48px;
                        width: 42px;
                        margin-right: 11px;
                    }

                    .hotNumber {
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }
                }
            }

            .top2Box,
            .top3Box {
                position: relative;
                margin-top: 14px;
                width: 280px;

                .topBg {
                    position: absolute;
                    display: block;
                    width: 280px;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    height: 180px;
                    width: 180px;
                    background: #ccc;
                    border-radius: 16px;
                    margin: 84px auto 0;
                }

                .roomName {
                    position: relative;
                    width: 180px;
                    font-size: 16px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;
                    margin: 35px auto 0;
                }

                .hotBox {
                    position: relative;
                    margin-top: 34px;

                    .iconHot {
                        display: block;
                        height: 48px;
                        width: 42px;
                        margin-right: 11px;
                    }

                    .hotNumber {
                        font-size: 32px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }
                }
            }

            .top2Box {
                margin-right: 135px;
            }

            .list {
                width: 1250px;
                height: 980px;
                overflow-y: scroll;
                margin: 96px auto 0;

                /* 定义滚动条样式 */
                &::-webkit-scrollbar {
                    width: 12px;
                    background: rgba(255, 255, 255, 0.4);
                    // border-radius: 8px;
                }

                /*定义滑块 内阴影+圆角*/
                &::-webkit-scrollbar-thumb {
                    width: 12px;
                    background: #AAF4FF;
                    // border-radius: 8px;
                }

                .item {
                    width: 1200px;
                    height: 140px;

                    &.itemBg {
                        background: rgba(255, 255, 255, 0.2);
                    }

                    .sortNum {
                        width: 72px;
                        height: 72px;
                        line-height: 72px;
                        text-align: center;
                        background: #B8F6FF;
                        border-radius: 48px;
                        font-size: 48px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #00144E;
                        margin-left: 27px;
                    }

                    .roomImg {
                        display: block;
                        width: 100px;
                        height: 100px;
                        background: #ccc;
                        border-radius: 16px;
                        margin-left: 32px;
                    }

                    .roomName {
                        width: 332px;
                        font-size: 24px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                        margin: 0 32px;
                    }

                    .hotBox {
                        padding-right: 20px;

                        .iconHot {
                            height: 48px;
                            width: 42px;
                            margin-right: 11px;
                        }

                        .hotNumber {
                            font-size: 32px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .ruleBox {
                padding-top: 150px;

                .ruleTitle {
                    display: block;
                    height: 104px;
                    width: 417px;
                    margin: 0 auto;
                }

                .ruleContent {
                    margin: 48px auto 0;
                    height: 522px;
                    width: 1200px;
                    background: url('../../assets/imgs/activity/20231111/pc_ruleBg.png')no-repeat center/cover;
                    font-size: 32px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B8F6FF;
                    padding: 117px 67px 0 68px;
                    box-sizing: border-box;
                }
            }

            .rewardBox {
                padding-top: 128px;

                .rewardTitle {
                    display: block;
                    height: 104px;
                    width: 417px;
                    margin: 0 auto;
                }

                .rewardContent {
                    margin: 48px auto 0;
                    height: 744px;
                    width: 1200px;
                    background: url('../../assets/imgs/activity/20231111/pc_rewardBg.png')no-repeat center/cover;
                    font-size: 32px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B8F6FF;
                    padding: 80px 67px 0 68px;
                    box-sizing: border-box;

                    .desc {
                        font-size: 20px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 29px;
                    }
                }
            }

            .descBox {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin: 0 auto;
                text-align: center;
                padding-top: 200px;
                padding-bottom: 92px;
            }
        }



        .noActivityBox {
            height: 100%;
            width: 1200px;
            margin: 0 auto;

            .mask {
                position: absolute;
                left: 50%;
                top: 0;
                height: 1080px;
                width: 1920px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.5);
            }

            .popBox {
                padding-top: 35px;
                width: 405px;
                height: 191px;
                background: #463B50;
                border-radius: 23px;
                box-sizing: border-box;
                position: absolute;
                left: 50%;
                top: 467px;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                z-index: 1;

                .icon_shalou {
                    height: 50px;
                    width: 40px;
                    display: block;
                    margin-right: 18px;
                }

                .text {
                    font-size: 24px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                }


                .btnConfirm {
                    height: 42px;
                    width: 102px;
                    background: url(../../assets/imgs/activity/icon_confirm.png)no-repeat center/cover;
                    margin: 35px auto 0;
                    cursor: pointer;
                }
            }

        }
    }

    // 移动端
    .main {
        margin: 0 auto;
        width: 100%;
        position: relative;
        user-select: none;
        box-sizing: content-box;
        z-index: auto;

        .bg_activity {
            width: 100vw;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }

        .topTitle {
            position: relative;
            margin: 8.533vw auto 16vw;
            display: block;
            height: 53.067vw;
            width: 72.267vw;
            z-index: 1;
        }

        .content {
            position: relative;

            .topBox {
                position: relative;
                margin: 0 auto;
                width: 54.133vw;
                padding: 1px;
                box-sizing: border-box;
                margin-bottom: 8.533vw;

                .topBg {
                    position: absolute;
                    display: block;
                    width: 54.133vw;
                }

                .roomImg {
                    position: relative;
                    display: block;
                    height: 36.267vw;
                    width: 36.267vw;
                    background: #ccc;
                    border-radius: 2.133vw;
                    margin: 14.933vw auto 0;
                }

                .roomName {
                    position: relative;
                    width: 33.333vw;
                    font-size: 3.2vw;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FFFFFF;
                    text-align: center;
                    margin: 6.667vw auto 0;
                }

                .hotBox {
                    position: relative;
                    margin-top: 6.4vw;

                    .iconHot {
                        display: block;
                        height: 6.4vw;
                        width: 5.6vw;
                        margin-right: 2.133vw;
                    }

                    .hotNumber {
                        font-size: 4.267vw;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }
                }
            }

            .top1Box {
                .iconWing {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    display: block;
                    width: 100vw;
                    transform: translate(-50%, -67%);
                }
            }

            .list {
                height: 149.333vw;
                overflow-y: scroll;
                margin: 0 5.333vw;

                /* 定义滚动条样式 */
                &::-webkit-scrollbar {
                    width: 0.533vw;
                    background: rgba(255, 255, 255, 0.4);
                    // border-radius: 8px;
                }

                /*定义滑块 内阴影+圆角*/
                &::-webkit-scrollbar-thumb {
                    width: 0.533vw;
                    background: #AAF4FF;
                    // border-radius: 8px;
                }

                .item {
                    width: 85.333vw;
                    height: 21.333vw;

                    &.itemBg {
                        background: rgba(255, 255, 255, 0.2);
                    }

                    .sortNum {
                        width: 8.533vw;
                        height: 8.533vw;
                        line-height: 8.533vw;
                        text-align: center;
                        background: #B8F6FF;
                        border-radius: 50%;
                        font-size: 5.333vw;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #00144E;
                        margin-left: 3.733vw;
                    }

                    .roomImg {
                        display: block;
                        width: 17.067vw;
                        height: 17.067vw;
                        background: #ccc;
                        border-radius: 2.133vw;
                        margin-left: 5.867vw;
                        margin-right: 3.2vw;
                    }

                    .roomName {
                        width: 44vw;
                        font-size: 3.2vw;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FFFFFF;
                    }

                    .hotBox {
                        margin-top: 2.4vw;

                        .iconHot {
                            height: 4.267vw;
                            width: 3.733vw;
                            margin-right: 1.067vw;
                        }

                        .hotNumber {
                            font-size: 3.2vw;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .ruleBox {
                padding-top: 12.8vw;

                .ruleTitle {
                    display: block;
                    height: 13.6vw;
                    width: 54.667vw;
                    margin: 0 auto;
                }

                .ruleContent {
                    margin: 6.4vw auto 0;
                    height: 93.6vw;
                    width: 88.267vw;
                    background: url('../../assets/imgs/activity/20231111/h5_ruleBg.png')no-repeat center/cover;
                    font-size: 4.267vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B8F6FF;
                    padding: 13.333vw 5.6vw 0 4.8vw;
                    box-sizing: border-box;
                }
            }

            .rewardBox {
                padding-top: 13.333vw;

                .rewardTitle {
                    display: block;
                    height: 13.6vw;
                    width: 54.667vw;
                    margin: 0 auto;
                }

                .rewardContent {
                    margin: 6.4vw auto 0;
                    height: 122.933vw;
                    width: 88.267vw;
                    background: url('../../assets/imgs/activity/20231111/h5_rewardBg.png')no-repeat center/cover;
                    font-size: 4.267vw;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #B8F6FF;
                    padding: 12.8vw 4.8vw 0 5.6vw;
                    box-sizing: border-box;

                    .desc {
                        font-size: 2.667vw;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        margin-top: 29px;
                    }
                }
            }

            .descBox {
                width: 86.933vw;
                font-size: 3.2vw;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                margin: 0 auto;
                text-align: center;
                padding-top: 10.667vw;
                padding-bottom: 13.333vw;
            }
        }
    }
}
</style>
