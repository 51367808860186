var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "box d-flex flex-column flex-1" }, [
    !_vm.isLoading && !_vm.isMobile
      ? _c(
          "div",
          { staticClass: "mainPC flex-1", staticStyle: { width: "1250px" } },
          [
            _c("img", {
              staticClass: "bg_activity",
              attrs: {
                src: require("../../assets/imgs/activity/20231225/pc_bg.jpg"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "topBox" }, [
                _c("img", {
                  staticClass: "topBg",
                  attrs: {
                    src: require("../../assets/imgs/activity/20231225/pc_topBg.png"),
                    alt: "",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "list d-flex flex-wrap" },
                  _vm._l(_vm.giftList, function (item, index) {
                    return _c("div", { key: index, staticClass: "item" }, [
                      _c("div", { staticClass: "imgBox" }, [
                        _c("img", {
                          class: ["img", { active: item.receivedStatus }],
                          attrs: { src: item.iconUrl, alt: "" },
                        }),
                      ]),
                      _c("div", { staticClass: "text" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ])
                  }),
                  0
                ),
                _c("div", {
                  class: [
                    "btn",
                    { btn1: _vm.receivedStatus == 0 },
                    { btn2: _vm.receivedStatus == 1 },
                    { btn3: _vm.receivedStatus == 2 },
                  ],
                  on: { click: _vm.receiveHandle },
                }),
                _vm.receivedStatus == 2
                  ? _c("div", { staticClass: "receivedText" }, [
                      _vm._v(_vm._s(_vm.receivedGiftName)),
                    ])
                  : _vm._e(),
              ]),
              _vm._m(0),
              _vm._m(1),
            ]),
            _vm.isShowReceivePop
              ? _c("div", { staticClass: "receivePop" }, [
                  _c("div", { staticClass: "mask" }),
                  _c("div", { staticClass: "popBox" }, [
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "已点亮" +
                          _vm._s(_vm.receivedCount) +
                          "个礼物，兑换超值礼物，只可选择一档兑换，是否继续？"
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "btnBox d-flex a-center j-center" },
                      [
                        _c("div", {
                          staticClass: "btn cancel",
                          on: { click: _vm.cancelHandle },
                        }),
                        _c("div", {
                          staticClass: "btn confirm",
                          on: { click: _vm.confirmHandle },
                        }),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    !_vm.isLoading && _vm.isMobile
      ? _c("div", { staticClass: "main" }, [
          _c("img", {
            staticClass: "bg_activity",
            attrs: {
              src: require("../../assets/imgs/activity/20231225/h5_bg.jpg"),
              alt: "",
            },
          }),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "topBox" }, [
              _c("img", {
                staticClass: "topBg",
                attrs: {
                  src: require("../../assets/imgs/activity/20231225/h5_topBg.png"),
                  alt: "",
                },
              }),
              _c(
                "div",
                { staticClass: "list d-flex flex-wrap" },
                _vm._l(_vm.giftList, function (item, index) {
                  return _c("div", { key: index, staticClass: "item" }, [
                    _c("div", { staticClass: "imgBox" }, [
                      _c("img", {
                        class: ["img", { active: item.receivedStatus }],
                        attrs: { src: item.iconUrl, alt: "" },
                      }),
                    ]),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(_vm._s(item.name)),
                    ]),
                  ])
                }),
                0
              ),
              _c("div", {
                class: [
                  "btn",
                  { btn1: _vm.receivedStatus == 0 },
                  { btn2: _vm.receivedStatus == 1 },
                  { btn3: _vm.receivedStatus == 2 },
                ],
                on: { click: _vm.receiveHandle },
              }),
              _vm.receivedStatus == 2
                ? _c("div", { staticClass: "receivedText" }, [
                    _vm._v(_vm._s(_vm.receivedGiftName)),
                  ])
                : _vm._e(),
            ]),
            _vm._m(2),
            _vm._m(3),
          ]),
          _vm.isShowReceivePop
            ? _c("div", { staticClass: "receivePop" }, [
                _c("div", { staticClass: "mask" }),
                _c("div", { staticClass: "popBox" }, [
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      "已点亮" +
                        _vm._s(_vm.receivedCount) +
                        "个礼物，兑换超值礼物，只可选择一档兑换，是否继续？"
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "btnBox d-flex a-center j-center" },
                    [
                      _c("div", {
                        staticClass: "btn cancel",
                        on: { click: _vm.cancelHandle },
                      }),
                      _c("div", {
                        staticClass: "btn confirm",
                        on: { click: _vm.confirmHandle },
                      }),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("img", {
        staticClass: "ruleBg",
        attrs: {
          src: require("../../assets/imgs/activity/20231225/pc_ruleBg.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _c("img", {
        staticClass: "bottomBg",
        attrs: {
          src: require("../../assets/imgs/activity/20231225/pc_bottom.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ruleBox" }, [
      _c("img", {
        staticClass: "ruleBg",
        attrs: {
          src: require("../../assets/imgs/activity/20231225/h5_ruleBg.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descBox" }, [
      _c("img", {
        staticClass: "bottomBg",
        attrs: {
          src: require("../../assets/imgs/activity/20231225/h5_bottom.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }